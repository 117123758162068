/*
 * Third party
 */


// Custom
// = partials/app.js
$(window).scroll(function () {
    var menuWrapper = $('.full-menu').offset().top;
    if (menuWrapper >= 100) {
        $('.full-menu').addClass('small-menu');
        $('.full-menu').removeClass('full-menu-active');
    } else {
        $('.full-menu').removeClass('small-menu');
        $('.full-menu').addClass('full-menu-active');

    }
});
//Slider header index.
$('.header-page').slick({
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1
  
});

//Slider best product.
$('.best-product-slider').slick({
  infinite: true,
  arrows: false,
  dots: true,
  cssEase: 'ease-out',
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToScroll: 3,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 769,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
});

//Slider novelty product.
$('.novelty-slider').slick({
  infinite: true,
  arrows: false,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1
});

//Slider images product.
$('.images-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.images-nav'
});
$('.images-nav').slick({
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.images-for',
  variableWidth: true,
  focusOnSelect: true
});
// Variable
var tabs = $('.tabs-nav'),
    items = $('.tabs-nav').find('tab-nav').length,
    selector = $(".tabs").find(".selector"),
    activeItem = tabs.find('.active'),
    activeWidth = activeItem.innerWidth();

if ($('.selector').length > 0) {
    $('.selector').css({
        "left": activeItem.position.left + "px",
        "width": activeWidth + "px"
    });
    // console.log(1)
}
//Tabs items.
$(document).on('click', '.tabs-nav .tab-nav', function () { // Click event on the tab menu item.
    var $this = $(this)
    if (!$this.hasClass('active')) { // Check if we clicked on an already active item
        var i = $this.index() - 1; // We get the serial number of the pressed point, it goes from 0 (0,1,2)
        if ($this.parents().eq(1).hasClass('modal')) {
            // i = i-1;
            $this.closest('.tabs-nav').parent().find('.tabs-items').find('.active').hide().removeClass('active'); // Hide and delete the active class from the previous container with the contents
            $this.closest('.tabs-nav').parent().find('.tabs-items').find('.tab-item:eq(' + i + ')').fadeIn('fast').addClass('active'); // We show and add the active class to the corresponding container
        } else {
            $this.closest('.tabs-nav').parent().siblings('.tabs-items').find('.active').hide().removeClass('active'); // Hide and delete the active class from the previous container with the contents
            $this.closest('.tabs-nav').parent().siblings('.tabs-items').find('.tab-item:eq(' + i + ')').fadeIn('fast').addClass('active'); // We show and add the active class to the corresponding container
        }
        $this.closest('.tabs-nav').find('.active').removeClass('active'); // Delete the active class from the previous menu item
        $this.addClass('active'); // Add the active class to the menu item

        console.log($this.closest('.tabs-nav').parent().siblings('.tabs-items').html());
    };

    $this.closest('.tabs-nav').find('.active').removeClass("active");
    $this.addClass('active'); // Add class active.
    var activeWidth = $this.innerWidth(); // Variable active element wirdh
    var itemPos = $this.position(); // Variable position this element
    if (!$this.parents().eq(1).hasClass('modal')) {
        $(".selector").css({
            "left": itemPos.left + "px",
            "width": activeWidth + "px"
        });
    }
});

//Tabs delivery (radio button).
$(document).on('change', '.list-delivery-type input', function () {
    var $this = $(this),
        nameDelivery = $this.closest('.delivery-item').data('delivery');

    $('.container-info-delivery>div').removeClass('show');
    $('.container-info-delivery').find('.' + nameDelivery).addClass('show');
})
$('body').on('click', '.js-btn-modal', function () {
    var name = $(this).data('btn');
    $(".modal[data-modal='" + name +"']").fadeIn('fast').css('display', 'flex');
})

// Close modal
$('body').on('click', '.js-close', function (e) {
    var $this = $(this).closest('.modal');
    $this.fadeOut('fast')
    if ($this.hasClass('modal-filter')) {
        $('.modal input[type="number"]').val('');
        $('.modal input[type="checkbox"]').prop('checked', false);
        
    }
    
});

$('body').on('click', '.js-done', function (e) {
    $(this).closest('.modal').fadeOut('fast')
    
});

$(document).mouseup(function (e){
    var modal = $(".modal");
    if (!modal.is(e.target) 
        && modal.has(e.target).length === 0) {
        modal.fadeOut('fast');
    }
});

//Anchor.
$(document).on('click','.anchor', function (event) {
    event.preventDefault();
    
    $("html, body").animate({
        scrollTop: $($(this).attr("href")).offset().top + "px"
     }, {
        duration: 1500,
        easing: "swing"
     });
     return false;

});


//Function max count product.
function maxCount() {
    var $thisCount = $('.js-count'),
        maxThisCount = parseInt($thisCount.attr('max')),
        thisValue = parseInt($thisCount.val());

    if (thisValue > maxThisCount) {
        $thisCount.val(maxThisCount);
    };
}

$(document).on('change keyup', '.js-count', function () {
    var $this = $(this),
        // maxThisCount = parseInt($this.attr('max')),
        // thisValue = parseInt($this.val()),
        count = $this.val() || 1,
        price = $('.js-price').text();

    maxCount();


    $('.js-total').text(count * price);
})

$(document).on('click', '.color-item', function () {
    var $this = $(this),
        maxCountValue = $this.data('count'),
        article = $this.data('article'),
        nameColor = $this.data('name'),
        priceProduct = $this.data('price'),
        wrapperArticle = $('.js-article'),
        wrapperColorName = $('.js-color-name'),
        wrapperPrice = $('.js-price'),
        inputCount = $('.js-count');

    // Paste to new article.
    wrapperArticle.text(article);
    wrapperPrice.text(priceProduct);
    wrapperColorName.text('(' + nameColor + ')');

    // Paste to new max count.
    inputCount.prop('max', maxCountValue);

    maxCount();
})

$('body').on('click', '.product-minus, .product-plus', function actionsButtons() {
    var obj = $(this).parent().find('input');
    var button_minus = $(this).parent().find('.product-minus');
    var val = parseInt(obj.val());

    if ($(this).hasClass('product-minus') && val > 1)
        val--;
    else if ($(this).hasClass('product-plus'))
        val++;

    if (val <= 1)
        button_minus.addClass('btn-empty');
    else
        button_minus.removeClass('btn-empty');


    obj.val(val);
    countBalance();
    discountBalance();
    maxCountProduct($(this));
});

$('body').on('keyup', '.product-value', function countKeyupBalance() {
    maxCountProduct($(this));
    countBalance();
    discountBalance();
});

discountBalance();

$('body').on('click', '.product-delete', function deleteOrder() {

});

// maxCountProduct();
function maxCountProduct($this) {
    var maxThisCount = parseInt($this.closest('.product-count').data('count')),
        test = parseInt($this.closest('.product-count').find('.product-value').val());

    if (test >= maxThisCount) {
        console.log(test)
        $this.closest('.product-count').find('.product-value').val(maxThisCount);
        $this.closest('.product-count').find('.product-plus').addClass('btn-empty');
    } else {
        $this.closest('.product-count').find('.product-plus').removeClass('btn-empty');
    };

    // if ($this.closest('.product-number').find('.product-value').val() < minThisCount) $this.closest('.product-number').find('.product-value').val(minThisCount);
};

function countBalance() {
    var balance = 0;

    $('.list-products > .item-product:visible').each(function () {
        balance += parseFloat($(this).find('input.product-value').val()) * parseFloat($(this).find('.product-price').data('price'));
    });

    if (!balance || balance < 0) {
        balance = 0;
    }

    $('#allBalance').text(balance);
}

function discountBalance() {
    var balance = $('#allBalance').text(),
        discountBalance = 0;


    $('.list-products > .item-product:visible').each(function () {
        discountBalance += ($(this).find('input.product-value').val()) * ($(this).find('.product-price').data('price') - ($(this).find('.product-price').data('price') * ($(this).find('.product-price').data('discount') / 100))).toFixed(2);

    });

    if (!discountBalance || discountBalance < 0) {
        discountBalance = 0;
    }

    if (balance != discountBalance) {
        discountBalance = discountBalance.toFixed(2);
        $('#allBalanceDiscount').text(parseFloat(discountBalance));
        $('#allBalanceDiscount').parent().fadeIn('fast');
    } else {
        $('#allBalanceDiscount').parent().fadeOut('fast');
    }
}


countBalance();
discountBalance();


//Mobile menu

var $button = $('#menu');

$button.on('click', function (e) {
    e.preventDefault();
    if ($button.hasClass('active')) {
        $button.removeClass('active');
        $('.mobile-nav').removeClass('show');
        $('.dark-blue').removeClass('slide');
        $('.mobile-menu-wrapper').removeClass('active');
    } else {
        $button.addClass('active');
        $('.mobile-menu-wrapper').addClass('active');
        $('.mobile-nav').addClass('show');
    }
});

$(document).on('click', '.mobile-menu-item-second>a', function (e) {
    e.preventDefault();
    $(this).closest('.mobile-menu-item-second').toggleClass('active');
})

$(document).on('click', '.link-lang-active', function (e) {
    e.preventDefault();
    $('.lang-items').toggleClass('active');

})
//Validation
var $errors = false;
$(document).on('submit', 'form', function () {
    var $this = $(this),
        $emailReg = /^([a-zA-Z0-9\\.]+)@([a-zA-Z0-9\\-\\_\\.]+)\.([a-zA-Z0-9]+)$/i,
        $phoneReg = /^\+38\s\((0\d{2})\)\s(\d{3})\s(\d{2})\s(\d{2})/,
        $emailInput = $this.find('.js-email:visible'),
        $phoneInput = $this.find('.js-phone:visible:enabled'),
        $requiredInput = $this.find('.js-required:visible'),
        $passwordInput = $this.find('.js-password:visible'),
        $repPasswordInput = $this.find('.js-rep-password:visible');
    // $('.errors').remove();
    $('div').removeClass('error-box');


    // required input validate
    requiredInput($requiredInput);

    //validate name field has entry
    emailInput($emailInput, $emailReg);

    // validate phone
    phoneInput($phoneInput, $phoneReg);

    // validate password
    passwordInput($passwordInput, $repPasswordInput);

    if ($('.email-wrapper').length > 0) {
        var activeWrapper;

        // Validate all fields to empty.
        var allInputs = $('.email-wrapper input:visible').filter(function () {
            if ($(this).val().trim() !== '') {
                return activeWrapper = $(this).closest('.email-wrapper');
            }
        });



        // If all input is empty add class error.
        if (allInputs.length === 0) {
            $('.email-wrapper input:visible').closest('.input-box').addClass('error-box');
            $errors = true;
        }

        if (activeWrapper) {
            var $requiredAside = activeWrapper.find('.js-required-aside:visible'),
                $emailAside = activeWrapper.find('.js-email-aside:visible'),
                $phoneAside = activeWrapper.find('.js-phone-aside:visible');
                
            requiredInput($requiredAside);
            emailInput($emailAside, $emailReg);
            phoneInput($phoneAside, $phoneReg)
        }

    }
    //validate select required.
    if ($('.js-select-required:visible').length > 0 && $('.js-select-required:visible select option:selected').is(':disabled')) {
        $('.js-select-required').addClass('error-box');
        $errors = true;
    };


    return !$errors;

});



// required input validate
function requiredInput($requiredInput) {
    if ($requiredInput.length > 0) {
        $requiredInput.each(function () {
            if ($(this).val().trim() === '') {
                $(this).parent().addClass('error-box');
                $errors = true;
            };
        });
    };
}
//validate name field has entry
function emailInput($emailInput, $emailReg) {
    if ($emailInput.length > 0 && ($emailInput.val().trim() === '' || !$emailReg.test($emailInput.val()))) {
        $emailInput.closest('.input-box').addClass('error-box');
        $errors = true;
    }
}

// validate phone
function phoneInput($phoneInput, $phoneReg) {
    if ($phoneInput.length > 0) {
        $phoneInput.each(function () {
            if ($(this).val().length < 19 || !phoneReg.test($(this).val())) {

                $(this).parent().addClass('error-box');
                $errors = true;
            };
        });
    };
}

// validate password
function passwordInput($passwordInput, $repPasswordInput) {
    if ($passwordInput.length > 0 && $repPasswordInput.length > 0) {
        var valueX = $passwordInput.val().trim();
        var valueY = $repPasswordInput.val().trim();
        if ((valueX !== valueY) || (valueX.length < 6)) {
            $passwordInput.closest('.input-box').addClass('error-box');
            $repPasswordInput.closest('.input-box').addClass('error-box');
            $errors = true;
        }
    };
}

//Trim 
$('body').on('keyup', '.js-slow', function () {
    str = $(this).val();
    str = str.replace(/\s/g, '');
    $(this).val(str);
});

//Rule text
$('body').on('keyup', 'input.js-text', function () {
    var $this = $(this);
    // run the expression and replace with nothing
    $this.val($this.val().replace(/[^a-zA-ZА-Яа-яІіЇїЄєЁё -]+/g, function () {
        return '';
    }));
});

//Mask phone
$('body').on('focus keyup', 'input.js-phone, input.js-phone-aside', function addPrefixToPhone() {
    var $this = $(this);
    var value = $this.val();

    // Lock all words and symbols.
    $this.val('+' + value.slice(1).replace(/[^\d\s\(\))]/g, '').trim());

    // Add to phone prefix.
    if (value.length < 4) {
        $this.val('+380');
        return;
    }

    // Remove many symbols.
    if (value.length > 20) {
        $this.val(value.slice(0, 19));
        return;
    }

    // Remove whitespase.
    value = value.replace(/\s/g, '');

    // Validate phone.
    value.replace(/^\+38(\d{3})$/, function ($1, $2) {
        $this.val('+38 (' + $2 + ') ')
    });

    value.replace(/^\+38\((\d{3})\)(\d{3})$/, function ($1, $2, $3) {
        $this.val('+38 (' + $2 + ') ' + $3)
    });

    value.replace(/^\+38\((\d{3})\)(\d{3})(\d{2})$/, function ($1, $2, $3, $4) {
        $this.val('+38 (' + $2 + ') ' + $3 + ' ' + $4)
    });

    value.replace(/^\+38\((\d{3})\)(\d{3})(\d{2})(\d{2})/, function ($1, $2, $3, $4, $5) {
        $this.val('+38 (' + $2 + ') ' + $3 + ' ' + $4 + ' ' + $5)
    });
});

// Responsive.
// Add filter Store.
function addFilter() {
    if ($('.filter-items').length == 1) {
        var filterDesktop = $('.store-wrapper .filter-items'),
            modalContainer = $('.modal-filter');
        filterDesktop.appendTo(modalContainer);
    }
};
// Remove filter Store.
function removeFilter() {
    var filterMobile = $('.modal-filter .filter-items'),
        asideContainer = $('.store-wrapper .store-items');
    asideContainer.before(filterMobile);
};

//Resize and load page.
$(window).on('load resize', function () {
    if ($(window).width() <= '1024') {
        addFilter();

        $(document).on('click', '.js-btn-modal', function () {
            $button.removeClass('active');
            $('.mobile-nav').removeClass('show');
            $('.dark-blue').removeClass('slide');
            $('.mobile-menu-wrapper').removeClass('active');
        });

        $(document).on('click','.anchor', function (event) {
            $button.removeClass('active');
            $('.mobile-nav').removeClass('show');
            $('.dark-blue').removeClass('slide');
            $('.mobile-menu-wrapper').removeClass('active');
        });
    } else {
        removeFilter();
    }
});